<template>
	<div>
		
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()" />
				</div>
			</div>
		</template>

		<b-card no-body>
			<b-card-body>
				<b-row>
					<b-col cols="12" md="4" sm="6" class="mobile_tab_max_width_flex">
						<b-form-select v-model="organization" @change="filterTable">
							<b-form-select-option value="">Select Client</b-form-select-option>
							<b-form-select-option :value="organization._id" v-for="(organization, ind) in organizations" :key="ind">{{organization.organization_name | capitalize}}</b-form-select-option>
						</b-form-select>				
					</b-col>				
				</b-row>
			</b-card-body>
		</b-card>

		<b-card no-body class="mb-0" >

			<div class="m-2">
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" >
						<label>Show</label>
							<v-select
								v-model="perPage"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
								@input="filterTable"
							/>
						<label>entries</label>
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6" >
						<div class="d-flex align-items-center justify-content-end mobile-view">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
								placeholder="Search..."
								@input="filterTable"
							/>
							<b-button variant="warning" :to="{name:'add-role-menu'}" class="mobile-margin-bottom" >
								<span class="text-nowrap">Add Role Permission</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table sticky-header
				ref="refUserListTable"
				class="position-relative sTable"
				:items="dataList"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
				:class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
			>
				<template #cell(role)="items">
					<span>{{ items.item.role | removeUnderCapitalize}}</span>
				</template>

				<template #cell(status)="items">
					<b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize" >
						{{ items.item.status}}
					</b-badge>
				</template>

				<template #cell(created_at)="items">
					<span>{{ items.item.created_at | dateTime}}</span>
				</template>

				<template #cell(actions)="items">
					<!-- <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
						<template #button-content>
							<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
						</template>
						<b-dropdown-item :to="{ name: 'edit-role-menu', params: { id: items.item._id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Edit</span>
						</b-dropdown-item>
						<b-dropdown-item @click="statusChange(items.item)">
							<feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
							<span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
							<span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
						</b-dropdown-item>
						<b-dropdown-item @click="deleteItem(items.item._id)">
							<feather-icon icon="Trash2Icon" />
							<span class="align-middle ml-50">Delete</span>
						</b-dropdown-item>						
					</b-dropdown>  -->
					

					 <b-link :to="{ name: 'edit-role-menu', params: { id: items.item._id } }"
						v-b-tooltip.hover.v-warning
						title="Edit"
						variant="outline-warning"
					>
						<feather-icon icon="EditIcon" class="mediumSize mr-1"/>
						
					</b-link>
					  <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
							<feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
						</b-link>

					 <b-link @click="deleteItem(items.item._id)"
						v-b-tooltip.hover.v-warning
						title="Delete"
						variant="outline-warning"
					>
						<feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
						
					</b-link>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >
						<span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
					</b-col>

					<!-- Pagination -->
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRecords"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
							@input="filterTable"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>

				</b-row>
			</div>

		</b-card>
	</div>
</template>

<script>
	import {
		BCard, BCardBody, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
		BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb, BFormSelect,BFormSelectOption
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import store from '@/store'
	import { ref, onUnmounted } from '@vue/composition-api'
	import { POST_API } from "../../../store/actions.type"

	export default {
		components: {
			BCard, BCardBody, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, 
			BDropdown, BDropdownItem, BPagination, BBreadcrumb, vSelect, BFormSelect,BFormSelectOption
		},
		 directives: { 'b-tooltip': VBTooltip },
		data() {
			return {
				organizations: [],
				organization: '',

				tableColumns: [
					{ key: 'organization.organization_name', label: 'Client', sortable: false , thStyle:  {width: '25%'}},
					{ key: 'role', label: 'Role', sortable: true , thStyle:  {width: '20%'}},
					{ key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
					{ key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '20%'}},
					{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '20%'}},
				],
				
				items          : [],
				isSortDirDesc  : true,
				perPageOptions : [10, 20, 50, 100],
				perPage        : 10,
				sortBy         : 'order',
				webUrl         : process.env.VUE_APP_SERVER_URL,
				totalRecords   : 0,
				currentPage    : 1,
				searchQuery    : '',
				from           : null,
				to             : null
			}
		},

		methods : {
			dataList(){
				return this.$store.dispatch(POST_API, {
					data:{
						organization  : this.organization,
						page          : this.currentPage,
						keyword       : this.searchQuery,
						rowsPerPage   : this.perPage,
						sortBy        : this.sortBy,
						sortDirection : this.isSortDirDesc,
					},
					api: '/api/role-menu-list'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.items        = this.$store.getters.getResults.data.docs;
						this.totalRecords = this.$store.getters.getResults.data.total;
						this.perPage      = this.$store.getters.getResults.data.limit;
						this.from         = this.$store.getters.getResults.data.from
						this.to           = this.$store.getters.getResults.data.to
						return this.items;
					}
				});
			},

			filterTable(){
				this.$refs.refUserListTable.refresh();
			},

			variantColor(status){
				if (status == 'active') {
					return 'success';
				} else {
					return 'primary';
				}
			},

			statusChange(item){
				if (item.status == 'active') {
					var msg = 'Are you sure want to inactivate this record?';
					var message='Record Inactivated Successfully.';
				} 
				else{
					var msg = 'Are you sure want to activate this record?';
					var message='Record Activated Successfully.';
				}

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id     : item._id,
								status : item.status
							},
							api:"/api/change-role-menu-status",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {								
								this.successAlert();
								this.$refs.refUserListTable.refresh();
							}
						});
					}
				})
				.catch(err => { })
			},

			deleteItem(id){
				var msg = 'Are you sure want to delete this record?';
				var message='Record Deleted Successfully.';

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id: id,
								status:'deleted'
							},
							api:"/api/change-role-menu-status",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								this.successAlert();
								this.$refs.refUserListTable.refresh();
							}
						});
					}
				})
				.catch(err => { })
			},

			getOrganizations(){
				return this.$store.dispatch(POST_API, {
					data:{},
					api: '/api/all-client-list'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						var data = this.$store.getters.getResults.data;
						this.organizations = data;
						return this.organizations;
					}
				});
			},

			breadCrumb(){
				var item = [{
					to:{name:'admin-dashboard'},
					text: 'Dashboard',
				},{
					to:null,
					text: 'Role Permission',
					active:true
				}];
				return item;
			},
		}, 
		mounted(){
			this.getOrganizations();
		}
	
	}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>
